import React, { useEffect, useState } from 'react';
import { AgxTextInput } from '@urbanx/agx-ui-components';
import './PersonName.scss';

const PersonName = ({
  id,
  label,
  required,
  onValueChanged,
  defaultValue = null,
  validate,
}) => {
  const [name, setName] = useState({
    firstName: defaultValue?.firstName ?? '',
    middleName: defaultValue?.middleName ?? '',
    lastName: defaultValue?.lastName ?? '',
  });

  useEffect(() => {
    onValueChanged({ id, value: name });
  }, [name]);

  return (
    <div className="agxPersonName">
      <AgxTextInput
        id="FirstName"
        placeholder="First Name"
        label={label}
        required={required}
        onInputValueChange={({ value }) => {
          setName({
            ...name,
            firstName: value,
          });
        }}
        stretch
        defaultValue={name.firstName}
        error={validate ? 'this field is required' : undefined}
      />
      <AgxTextInput
        id="MiddleName"
        placeholder="Middle Name"
        required={required}
        onInputValueChange={({ value }) => {
          setName({
            ...name,
            middleName: value,
          });
        }}
        stretch
        noHeader
        defaultValue={name.middleName}
      />
      <AgxTextInput
        id="LastName"
        placeholder="Last Name"
        required={required}
        onInputValueChange={({ value }) => {
          setName({
            ...name,
            lastName: value,
          });
        }}
        stretch
        noHeader
        defaultValue={name.lastName}
      />
    </div>
  );
};

export default PersonName;
