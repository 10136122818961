import React, { useState, useEffect } from 'react';
import {
  AgxRow,
  AgxLabel,
  AgxRadio,
  AgxTextInput,
  AgxDatePicker,
  AgxCaption,
  AgxColours,
  Images,
  AgxBodyText,
} from '@urbanx/agx-ui-components';
import './BuildingAndPest.scss';

const BuildingAndPestType = {
  NotApplicable: 'NotApplicable',
  NdaysFromDate: 'NdaysFromDate',
  OnSpecificDate: 'OnSpecificDate',
};

const BuildingAndPest = ({
  id,
  label,
  onValueChanged,
  defaultValue,
  required,
  validate,
}) => {
  const [buildingAndPestState, setBuildingAndPestState] = useState({
    condition: defaultValue?.condition,
    daysFromDate: defaultValue?.daysFromDate,
    referenceDate: defaultValue?.referenceDate,
  });

  const { condition, daysFromDate, referenceDate } = buildingAndPestState;

  useEffect(() => {
    onValueChanged({ id, value: buildingAndPestState });
  }, [buildingAndPestState]);

  return (
    <div className="agxRadio">
      <AgxRow fill spaceBetween>
        <AgxLabel medium>{label}</AgxLabel>
        {!required && (
          <AgxCaption colour={AgxColours.NEUTRAL_GREY_600}>optional</AgxCaption>
        )}
      </AgxRow>
      <div className="agxRadio-group">
        <AgxRadio
          id="notSubjectToBuildingAndPest"
          label="Not subject to building and pest"
          onCheckChanged={() =>
            setBuildingAndPestState({
              ...buildingAndPestState,
              condition: BuildingAndPestType.NotApplicable,
            })
          }
          checked={condition === BuildingAndPestType.NotApplicable}
        />
        <AgxRadio
          id="buildingAndPestDays"
          label="__day(s) from Contract Date"
          onCheckChanged={() =>
            setBuildingAndPestState({
              ...buildingAndPestState,
              condition: BuildingAndPestType.NdaysFromDate,
            })
          }
          checked={condition === BuildingAndPestType.NdaysFromDate}
          subContent={
            condition === BuildingAndPestType.NdaysFromDate && [
              <AgxRow centered mediumGap>
                <AgxTextInput
                  id="numberOfDays"
                  noHeader
                  numberOnly
                  noOptionalLabel
                  small
                  maxLength="2"
                  defaultValue={daysFromDate}
                  extraClasses="buildingAndPestTextInput"
                  onInputValueChange={({ value }) =>
                    setBuildingAndPestState({
                      ...buildingAndPestState,
                      daysFromDate: value,
                    })
                  }
                  rightIcon={<AgxLabel small>day(s)</AgxLabel>}
                  error={
                    validate && buildingAndPestState.daysFromDate === ''
                      ? 'Enter days'
                      : undefined
                  }
                />
                <AgxLabel small>from Contract Date</AgxLabel>
              </AgxRow>,
            ]
          }
        />
        <AgxRadio
          id="buildingAndPestDate"
          label="Specific Date"
          onCheckChanged={() =>
            setBuildingAndPestState({
              ...buildingAndPestState,
              condition: BuildingAndPestType.OnSpecificDate,
            })
          }
          checked={condition === BuildingAndPestType.OnSpecificDate}
          subContent={
            condition === BuildingAndPestType.OnSpecificDate && [
              <AgxDatePicker
                key="buildingAndPestSpecificDate"
                id="buildingAndPestSpecificDate"
                onValueChanged={({ value }) =>
                  setBuildingAndPestState({
                    ...buildingAndPestState,
                    referenceDate: value,
                  })
                }
                required
                noHeader
                noOptionalLabel
                defaultValue={referenceDate}
                date
                stretch
                error={
                  validate && referenceDate === '' ? 'Enter a date' : undefined
                }
              />,
            ]
          }
        />
      </div>
      {validate && required && !buildingAndPestState.condition && (
        <div className={`errorMessage`}>
          <Images.AlertCircle />
          <AgxBodyText small extraClasses={'error'}>
            {'Select an option'}
          </AgxBodyText>
        </div>
      )}
    </div>
  );
};

export default BuildingAndPest;
