import CampaignsList from './components/CampaignsList/CampaignsList';
import { NewFormButton } from './components/NewFormButton';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { AgxColumn, AgxHeader } from '@urbanx/agx-ui-components';
import { UrbanXFooter } from './components/UrbanXFooter/UrbanXFooter';
import './CampaignsViewPage.scss';
import SidebarLeaderboard from './components/SidebarLeaderboard/SidebarLeaderboard';
import { useTopPerformers } from 'features/reportingDashboard/topPerformersReducer';

const CampaignsViewPage = () => {
  const [userAccount] = useAzureAuth();
  const { stats, loading: loadingTopPerformers } = useTopPerformers();

  const agentLeaderboardStat = stats?.topPerformers;
  const agencyLeaderboardStat = stats?.topPartners;

  return (
    <div className="campaignsViewPage">
      {/* TODO: UNCOMMENT ONCE THE FUNCTIONALITY IS READY */}
      {/*<div className="headerOptions">
        <AgxRow
          mediumGap={ScreenSize() === Breakpoints.Mobile}
          veryLargeGap={ScreenSize() === Breakpoints.Desktop}
          end
        >
          <HeaderButton onClick={() => {}}>
            <InboxIcon />
          </HeaderButton>
          <HeaderButton
            onClick={() => {
              console.log("hello notification");
            }}
          >
            <NotificationIcon />
          </HeaderButton>
          <HeaderButton fill onClick={() => {}}>
            <AvatarImage />
          </HeaderButton>
        </AgxRow>
          </div>*/}
      <div className="dashboardTitle">
        <AgxHeader size={1} inverse>
          {`Welcome back, ${userAccount?.firstName || ''}`}
        </AgxHeader>
        <NewFormButton />
      </div>
      <div className="dashboardMain">
        <CampaignsList />
      </div>
      <div className="bodySectionSideBarContainer">
        <AgxColumn extraClasses="sidebarGap">
          <div>
            {/* TODO: Uncomment the below code once the team are allocated to particular agency */}
            {/* <AgxHeader size={6} inverse>
              Your Team
            </AgxHeader>
            {concierges.map((concierge) => (
              <ConciergeDetails key={concierge.id} concierge={concierge} />
            ))} */}
            {/* <img src={AutoPilotTicket} alt="Auto Pilot Ticket" /> */}
          </div>
          <div className="sidebarLeaderboard columnFill first-leaderboard">
            <SidebarLeaderboard
              loading={loadingTopPerformers}
              leaderboardStats={agencyLeaderboardStat}
              boardName="Partners"
            />
          </div>
          <div className="sidebarLeaderboard columnFill">
            <SidebarLeaderboard
              loading={loadingTopPerformers}
              leaderboardStats={agentLeaderboardStat}
              boardName="Performers"
            />
          </div>

          <UrbanXFooter />
        </AgxColumn>
      </div>
    </div>
  );
};

export default CampaignsViewPage;
