import React, { useEffect, useState } from 'react';
import FlatCommission from '../FlatCommission/FlatCommission';
import TieredCommission from '../TieredCommission/TieredCommission';
import VendorChoiceCommission from '../VendorChoiceCommission/VendorChoiceCommission';
import {
  AgxRadio,
  AgxLabel,
  AgxCaption,
  AgxColours,
} from '@urbanx/agx-ui-components';
import './Commission.scss';

const Commission = ({
  id,
  commissionTypes,
  onValueChanged,
  defaultValue = null,
  showCommissionRangeDisclaimer,
  validate,
}) => {
  const [flatCommission, setFlatCommission] = useState(
    defaultValue?.flatCommission ?? null
  );
  const [tieredCommission, setTieredCommission] = useState(
    defaultValue?.tieredCommission ?? null
  );
  const [vendorChoiceCommission, setVendorChoiceCommission] = useState(
    defaultValue?.vendorChoice ?? null
  );

  const getDefaultCommissionPlan = defaultVal => {
    if (defaultVal == null) return '';
    if (defaultVal.flatCommission != null) return 'Flat';
    if (defaultVal.tieredCommission != null) return 'Tiered';
    if (defaultVal.vendorChoice != null) return 'VendorChoice';
    return '';
  };

  const [selectedCommissionPlan, setSelectedCommissionPlan] = useState(
    getDefaultCommissionPlan(defaultValue)
  );

  const getSubContent = commissionType => {
    if (selectedCommissionPlan !== commissionType) return null;
    if (selectedCommissionPlan === 'Flat')
      return [
        <FlatCommission
          id="flat commission"
          key="flat commission"
          onValueChanged={({ value }) => setFlatCommission(value)}
          defaultValue={flatCommission}
          validate={validate}
        />,
      ];
    if (selectedCommissionPlan === 'Tiered')
      return [
        <TieredCommission
          id="tiered commission"
          key="tiered commission"
          onValueChanged={({ value }) => setTieredCommission(value)}
          defaultValue={tieredCommission}
          validate={validate}
        />,
      ];
    if (selectedCommissionPlan === 'VendorChoice')
      return [
        <VendorChoiceCommission
          id="vendorChoice commission"
          key="vendorChoice commission"
          onValueChanged={({ value }) => setVendorChoiceCommission(value)}
          defaultValue={vendorChoiceCommission}
          showCommissionRangeDisclaimer={showCommissionRangeDisclaimer}
          validate={validate}
        />,
      ];

    return null;
  };

  const getCommissionLabel = commissionType => {
    switch (commissionType) {
      case 'Flat':
        return 'Standard';
      case 'Tiered':
        return 'Tiered Commission';
      case 'VendorChoice':
        return 'Vendor Choice';
      default:
        break;
    }
  };

  useEffect(() => {
    let values = { id };

    switch (selectedCommissionPlan) {
      case 'Flat':
        values.flatCommission = flatCommission;
        break;
      case 'Tiered':
        values.tieredCommission = tieredCommission;
        break;
      case 'VendorChoice':
        values.vendorChoice = vendorChoiceCommission;
        break;
      default:
        break;
    }

    onValueChanged({ id, value: values });
  }, [
    selectedCommissionPlan,
    flatCommission,
    tieredCommission,
    vendorChoiceCommission,
  ]);

  return (
    <div className="agxRadio">
      <AgxLabel large>Commission Plan</AgxLabel>
      <AgxCaption colour={AgxColours.NEUTRAL_GREY_700}>
        All percentages and figures are inclusive of GST
      </AgxCaption>
      <div className="agxRadio-group">
        {commissionTypes.map(commissionType => (
          <AgxRadio
            key={commissionType}
            id={commissionType}
            label={getCommissionLabel(commissionType)}
            onCheckChanged={() => setSelectedCommissionPlan(commissionType)}
            checked={selectedCommissionPlan === commissionType}
            subContent={getSubContent(commissionType)}
          />
        ))}
      </div>
    </div>
  );
};

export default Commission;
