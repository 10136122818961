import { useEffect, useState, useMemo } from 'react';
import {
  AgxColumn,
  AgxInlineSearch,
  AgxRow,
  AgxBodyText,
  Images,
  CampaignStage,
  useWindowSize,
  Breakpoints,
  EnvelopeSubmissionStatus,
  CampaignDetailModel,
} from '@urbanx/agx-ui-components';
import {
  campaignHasBeenViewed,
  archiveCampaignHasBeenViewed,
  fetchAllCampaigns,
  getArchivedCampaigns,
  selectCampaign,
} from 'features/campaigns/campaignsReducer';
import { LoadingState } from 'utils/loadingState';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { GroupedCampaigns } from './components/GroupedCampaigns/GroupedCampaigns';
import { SelectedCampaignModal } from './components/SelectedCampaignModal';
import { useSelectedCampaign } from 'hooks/useSelectedCampaign';
import { CampaignInfoPanelLoader } from '../CampaignInfoPanelLoader/CampaignInfoPanelLoader';
import { CampaignsViewPageImageCard } from '../CampaignsViewPageImageCard/CampaignsViewPageImageCard';
import { CampaignsTabs } from '../CampaignTabs/CampaignsTabs';
import { CampaignsListLoader } from '../CampaignsListLoader';
import CampaignInfoPanel from '../CampaignInfoPanel/CampaignInfoPanel';
import { getEnumValue } from 'helpers/enumHelper';
import { Tabs } from 'types/Tabs';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import './CampaignsList.scss';

const CampaignsList = () => {
  const tabs = [Tabs.All, Tabs.Agreements, Tabs.Listings, Tabs.Archive];
  const {
    loadingState: campaignsLoadingState,
    campaigns,
    archivedCampaigns,
  } = useAppSelector(state => state.campaigns);
  const selectedCampaign = useSelectedCampaign();

  const windowSize = useWindowSize();
  const [userAccount, getAuthToken] = useAzureAuth();
  const [showSelectedCampaignModal, setShowSelectedCampaignModal] =
    useState(false);
  const [currentTab, setCurrentTab] = useState(Tabs.All);
  const [filteredCampaigns, setFilteredCampaigns] = useState(campaigns);
  const [searchingCampaigns, setSearchingCampaigns] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const dispatch = useAppDispatch();

  const onCampaignClick = (campaignId: string) => {
    dispatch(selectCampaign(campaignId));

    if (currentTab === Tabs.Archive) {
      const matchingArchiveCampaign = archivedCampaigns.find(
        campaign => campaign.campaignId === campaignId
      );
      if (matchingArchiveCampaign != null) {
        dispatch(archiveCampaignHasBeenViewed(matchingArchiveCampaign));
      }
    } else {
      const matchingCampaign = campaigns.find(
        campaign => campaign.campaignId === campaignId
      );
      if (matchingCampaign != null) {
        dispatch(campaignHasBeenViewed(matchingCampaign));
      }
    }

    if (windowSize === Breakpoints.Mobile) {
      setShowSelectedCampaignModal(true);
    }
  };

  useEffect(() => {
    if (!userAccount || campaignsLoadingState !== LoadingState.NotLoaded)
      return;

    getAuthToken().then(token => {
      if (!token) return;
      dispatch(fetchAllCampaigns(token));
      dispatch(getArchivedCampaigns(token));
    });
  }, [userAccount, campaignsLoadingState]);

  const getTabFilteredCampaigns = (): CampaignDetailModel[] => {
    switch (currentTab) {
      case Tabs.All:
        return campaigns;
      case Tabs.Agreements:
        return campaigns.filter(campaign =>
          [
            CampaignStage.AgreementDraft,
            CampaignStage.AgreementVendorSigning,
            CampaignStage.AgreementAgentSigning,
            CampaignStage.AgreementSignedByAllParties,
            CampaignStage.AgreementSubmissionIncomplete,
          ].includes(getEnumValue(CampaignStage, campaign.stage))
        );
      case Tabs.Listings:
        return campaigns.filter(campaign =>
          [
            CampaignStage.ListingCurrent,
            CampaignStage.ListingUnderContract,
          ].includes(getEnumValue(CampaignStage, campaign.stage))
        );
      case Tabs.Archive:
        return archivedCampaigns;
      default:
        break;
    }

    return [];
  };

  useEffect(() => {
    var tabFilteredCampaigns = getTabFilteredCampaigns();
    var searchTermFilteredCampaigns = filterCampaignsWithSearchTerm(
      tabFilteredCampaigns,
      searchTerm
    );
    setFilteredCampaigns(searchTermFilteredCampaigns);

    if (
      tabFilteredCampaigns.length > 0 &&
      !tabFilteredCampaigns.some(
        c => c.campaignId === selectedCampaign?.campaignId
      )
    ) {
      dispatch(selectCampaign(tabFilteredCampaigns[0].campaignId));
    } else if (tabFilteredCampaigns.length === 0) {
      dispatch(selectCampaign(null));
    }
  }, [currentTab, campaigns, searchTerm]);

  const filterCampaignsWithSearchTerm = (
    campaigns: CampaignDetailModel[],
    searchTerm: string | null
  ) => {
    if (!searchTerm) return campaigns;

    const filterValue = searchTerm.toLowerCase();

    return campaigns.filter(campaign => {
      const { formattedAddress } = campaign?.address ?? {};
      return (
        formattedAddress
          ?.toLowerCase()
          ?.replaceAll(',', '')
          ?.includes(filterValue) ?? false
      );
    });
  };
  const handleStatusFilterForToActionCampaigns = ({
    stage,
    agencyAgreementSubmission,
  }: CampaignDetailModel) => {
    const declinedStatus =
      agencyAgreementSubmission?.status === EnvelopeSubmissionStatus.Declined;
    const bouncedRecipient = agencyAgreementSubmission?.recipients?.find(
      recipient => recipient.status === EnvelopeSubmissionStatus.Bounced
    );

    const isListingGeneratorIncompleteBouncedDeclined =
      stage === CampaignStage.ListingCurrent &&
      (declinedStatus || bouncedRecipient);

    if (
      isListingGeneratorIncompleteBouncedDeclined ||
      stage === CampaignStage.AgreementSubmissionIncomplete
    ) {
      return true;
    }

    return false;
  };

  const toActionCampaigns = useMemo(() => {
    return filteredCampaigns.filter(handleStatusFilterForToActionCampaigns);
  }, [filteredCampaigns]);

  const underContractCampaigns = useMemo(() => {
    return filteredCampaigns.filter(
      c =>
        c.stage === CampaignStage.ListingUnderContract &&
        !toActionCampaigns.includes(c)
    );
  }, [filteredCampaigns, toActionCampaigns]);

  const allCampaigns = useMemo(() => {
    return filteredCampaigns.filter(
      c => ![...toActionCampaigns, ...underContractCampaigns].includes(c)
    );
  }, [filteredCampaigns, toActionCampaigns, underContractCampaigns]);

  return (
    <>
      <div className="dashboardInbox">
        <AgxRow fill spaceBetween>
          <AgxInlineSearch
            onSearchFocus={searchFocused =>
              setSearchingCampaigns(searchFocused)
            }
            onValueChanged={value => setSearchTerm(value)}
          />
        </AgxRow>
        <CampaignsTabs
          tabs={tabs}
          tabChanged={setCurrentTab}
          selectedTab={currentTab}
        />

        {(campaignsLoadingState === LoadingState.NotLoaded ||
          campaignsLoadingState === LoadingState.Loading) && (
          <CampaignsListLoader />
        )}

        {campaignsLoadingState === LoadingState.Loaded && (
          <AgxColumn mediumGap fill scrollable>
            {searchingCampaigns && (
              <>
                {filteredCampaigns?.length === 0 && (
                  <AgxColumn fill centered>
                    <Images.MenuArrowCircle />
                    <AgxBodyText medium>No results for your search</AgxBodyText>
                  </AgxColumn>
                )}
                {filteredCampaigns?.map(campaign => (
                  <CampaignsViewPageImageCard
                    key={campaign.campaignId}
                    onClick={() => onCampaignClick(campaign.campaignId)}
                    campaign={campaign}
                  />
                ))}
              </>
            )}

            {!searchingCampaigns && (
              <>
                {filteredCampaigns?.length === 0 && (
                  <AgxBodyText large>No campaigns currently active</AgxBodyText>
                )}
                {currentTab === Tabs.Archive &&
                  filteredCampaigns.map(campaign => (
                    <CampaignsViewPageImageCard
                      key={campaign.campaignId}
                      onClick={() => onCampaignClick(campaign.campaignId)}
                      campaign={campaign}
                    />
                  ))}
                {currentTab !== Tabs.Archive &&
                  filteredCampaigns?.length > 0 && (
                    <>
                      <GroupedCampaigns
                        unread
                        campaigns={toActionCampaigns}
                        onCampaignClick={onCampaignClick}
                      />

                      <GroupedCampaigns
                        campaigns={allCampaigns}
                        onCampaignClick={onCampaignClick}
                      />

                      <GroupedCampaigns
                        title="Under Contract"
                        campaigns={underContractCampaigns}
                        onCampaignClick={onCampaignClick}
                      />
                    </>
                  )}
              </>
            )}
          </AgxColumn>
        )}
      </div>
      {(campaignsLoadingState === LoadingState.NotLoaded ||
        campaignsLoadingState === LoadingState.Loading) && (
        <CampaignInfoPanelLoader />
      )}
      {windowSize !== Breakpoints.Mobile && selectedCampaign != null && (
        <CampaignInfoPanel
          campaign={selectedCampaign}
          currentTab={currentTab}
        />
      )}
      {windowSize === Breakpoints.Mobile && showSelectedCampaignModal && (
        <SelectedCampaignModal
          selectedCampaign={selectedCampaign}
          currentTab={currentTab}
          onClosed={() => setShowSelectedCampaignModal(false)}
        />
      )}
    </>
  );
};

export default CampaignsList;
