import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AgxLabel,
  AgxDivider,
  AgxColumn,
  AgxRow,
  Images,
  AgxBodyText,
} from '@urbanx/agx-ui-components';
import FileUpload from '../FileUpload/FileUpload';
import './TypedDocumentUpload.scss';
import isEqual from 'lodash.isequal';

const TypedDocumentUpload = ({
  id,
  title,
  documentConfig,
  defaultValue,
  onValueChanged,
  validate,
}) => {
  const { campaignId } = useSelector(state => state.form);
  const documents = defaultValue ?? [];
  const [uploadError, setUploadError] = useState(null);
  const updateDocumentDetail = (documentType, updatedDocumentDetails) => {
    const duplicateDocuments = documents.filter(
      d =>
        d.documentType !== documentType &&
        updatedDocumentDetails.some(
          u => u.containerFilePath === d.containerFilePath
        )
    );

    if (duplicateDocuments.length > 0) {
      setUploadError(
        'Cannot overwrite an existing document. Try use a different file name.'
      );
      return;
    }

    setUploadError(null);

    const updatedDocuments = documents
      .filter(d => d.documentType !== documentType)
      .concat(updatedDocumentDetails);

    const sortByFileName = docs =>
      [...docs].sort((a, b) => a.fileName.localeCompare(b.fileName));

    const sortedUpdatedDocuments = sortByFileName(updatedDocuments);
    const sortedDocuments = sortByFileName(documents);

    if (!isEqual(sortedUpdatedDocuments, sortedDocuments)) {
      onValueChanged({ id, value: updatedDocuments });
    }
  };

  return (
    <AgxColumn extraLargeGap>
      {title && <AgxLabel large>{title}</AgxLabel>}
      {documentConfig.map((file, index) => {
        const { documentType, description, required, readonly, multipleFiles } =
          file;

        const matchingDocuments = documents.filter(
          doc => doc.documentType === documentType
        );

        return (
          <React.Fragment key={index}>
            {index !== 0 ? <AgxDivider expanded /> : <></>}
            <FileUpload
              key={index}
              id={index}
              title={description}
              required={required}
              validate={validate}
              readonly={readonly}
              fileData={matchingDocuments}
              campaignId={campaignId}
              documentType={documentType}
              multiple={multipleFiles}
              onValueChanged={({ value }) =>
                updateDocumentDetail(documentType, value)
              }
            />
          </React.Fragment>
        );
      })}
      {uploadError && (
        <AgxRow fill mediumGap>
          <span>
            <Images.AlertCircle />
          </span>
          <AgxBodyText small>{uploadError}</AgxBodyText>
        </AgxRow>
      )}
    </AgxColumn>
  );
};

export default TypedDocumentUpload;
