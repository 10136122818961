import { useState, useEffect } from 'react';
import {
  AgxRow,
  AgxColumn,
  AgxLabel,
  AgxSelect,
  AgxTextInput,
  Images,
  Option,
} from '@urbanx/agx-ui-components';
import { createNumberArray } from 'utils/numbers';
import { IconDetails } from './types';

interface IconsProps {
  id: string;
  defaultValue: IconDetails;
  onChange: (value: { id: string; value: IconDetails }) => void;
}

const PropertyIcons = ({ id, defaultValue, onChange }: IconsProps) => {
  const numbers = createNumberArray(0, 20);
  const numberOptions = numbers.map(
    (number): Option => ({
      value: number.toString(),
      label: number.toString(),
    })
  );

  const defaultValueNumberOfBeds = defaultValue?.numberOfBeds ?? 0;
  const defaultValueNumberOfBathrooms = defaultValue?.numberOfBathrooms ?? 0;
  const defaultValueNumberOfCarSpaces = defaultValue?.numberOfCarSpaces ?? 0;

  const [numberOfBeds, setNumberOfBeds] = useState<Option>(
    {
      label: isNaN(defaultValueNumberOfBeds)
        ? ''
        : defaultValueNumberOfBeds.toString(),
      value: isNaN(defaultValueNumberOfBeds)
        ? ''
        : defaultValueNumberOfBeds.toString(),
    } ?? ''
  );
  const [numberOfBathrooms, setNumberOfBathrooms] = useState<Option>(
    {
      label: isNaN(defaultValueNumberOfBathrooms)
        ? ''
        : defaultValueNumberOfBathrooms.toString(),
      value: isNaN(defaultValueNumberOfBathrooms)
        ? ''
        : defaultValueNumberOfBathrooms.toString(),
    } ?? ''
  );
  const [numberOfCarSpaces, setNumberOfCarSpaces] = useState<Option>(
    {
      label: isNaN(defaultValueNumberOfCarSpaces)
        ? ''
        : defaultValueNumberOfCarSpaces.toString(),
      value: isNaN(defaultValueNumberOfCarSpaces)
        ? ''
        : defaultValueNumberOfCarSpaces.toString(),
    } ?? ''
  );
  const [totalLandArea, setTotalLandArea] = useState<number>(
    defaultValue?.totalLandArea ?? 0
  );

  useEffect(() => {
    onChange({
      id,
      value: {
        numberOfBeds: parseInt(numberOfBeds.value),
        numberOfBathrooms: parseInt(numberOfBathrooms.value),
        numberOfCarSpaces: parseInt(numberOfCarSpaces.value),
        totalLandArea,
      },
    });
  }, [numberOfBeds, numberOfBathrooms, numberOfCarSpaces, totalLandArea]);

  return (
    <AgxColumn veryLargeGap>
      <AgxLabel large>Property Attributes</AgxLabel>
      <AgxColumn mediumGap>
        <AgxRow largeGap>
          <AgxSelect
            id="Bed"
            label="Bed"
            options={numberOptions}
            icon={<Images.Bed />}
            onValueChanged={({ value }) =>
              setNumberOfBeds({ label: value, value: value })
            }
            defaultValue={numberOfBeds}
            hideOptionalLabel
          />
          <AgxSelect
            id="Bath"
            label="Bath"
            options={numberOptions}
            icon={<Images.Bath />}
            onValueChanged={({ value }) =>
              setNumberOfBathrooms({ label: value, value: value })
            }
            defaultValue={numberOfBathrooms}
            hideOptionalLabel
          />
        </AgxRow>
        <AgxRow veryLargeGap>
          <AgxSelect
            id="Car"
            label="Car"
            options={numberOptions}
            icon={<Images.Car />}
            onValueChanged={({ value }) =>
              setNumberOfCarSpaces({ label: value, value: value })
            }
            defaultValue={numberOfCarSpaces}
            hideOptionalLabel
          />
          <AgxTextInput
            id="LandSize"
            label="Land Size (m²)"
            onInputValueChange={({ value }) =>
              setTotalLandArea(parseInt(value))
            }
            stretch
            defaultValue={totalLandArea.toString()}
            noOptionalLabel
            landArea
          />
        </AgxRow>
      </AgxColumn>
    </AgxColumn>
  );
};

export default PropertyIcons;
