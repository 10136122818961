import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AgxBodyText,
  AgxLabel,
  AgxRow,
  Images,
  PropertyAddress,
  PropertyDetails,
  PropertyImageUrls,
  VendorBuyer,
} from '@urbanx/agx-ui-components';
import { throttle } from 'lodash';
import clsx from 'clsx';
import axios from 'axios';
import { fetchAddresses, fetchPlanSuggestions } from 'Api/RpData/rpDataApi';
import {
  AllPropertyDetails,
  PropertySuggestionItem,
} from 'Api/RpData/Types/types';
import { PageTitle } from 'features/form/FormHeader/components/PageTitle';
import {
  getPropertyDetails,
  clearPropertyDetails,
} from 'features/rpData/rpDataReducer';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useFormSettings } from 'hooks/useFormSettings';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { useCanAccessRural } from 'hooks/useCanAccessRural';
import { LoadingState } from 'utils/loadingState';
import { PropertySearchLoading } from './Loading/PropertySearchLoading';
import { PropertyConfirmation } from './PropertyConfirmation/PropertyConfirmation';
import CannotAccessRural from './CannotAccessRural/CannotAccessRural';
import NoPropertyFound from './NoPropertyFound/NoPropertyFound';
import './PropertySearch.scss';

interface PropertySearchProps {
  id: string;
  label?: string;
  disabled?: boolean;
  regionToSearch?: string | null;
  inverseLabel?: boolean;
  defaultValue?: string;
  error?: string | null;
  onManualAddress: () => void;
  onBeginCampaign: (
    address: PropertyAddress,
    imageUrls: PropertyImageUrls,
    propertyDetails: PropertyDetails,
    vendors: VendorBuyer[]
  ) => void;
  extraClasses?: string;
}

export enum Tabs {
  Address = 'Address',
  LotPlan = 'Lot/Plan',
}

enum PropertySearchSteps {
  Search = 'Search',
  PropertyLoading = 'ProperyLoading',
  PropertyConfirmation = 'PropertyConfirmation',
  NoPropertyFound = 'NoPropertyFound',
  CannotAccessRural = 'CannotAccessRural',
}

const classAugmenter = (
  className: string,
  localError: string | null,
  disabled: boolean
) => {
  return clsx(className, localError && 'error', disabled && 'disabled');
};

const PropertySearch: React.FC<PropertySearchProps> = ({
  id: addressId,
  error = null,
  label = null,
  disabled = false,
  inverseLabel = false,
  onManualAddress,
  onBeginCampaign,
}) => {
  const dispatch = useAppDispatch();
  const [, getAuthToken] = useAzureAuth();
  const updateFormSettings = useFormSettings();
  const navigate = useNavigate();

  const selectedForm = useAppSelector(state => state.form.selectedForm);
  const availableForms = useAppSelector(state => state.forms.availableForms);
  const propertyDetails = useAppSelector(state => state.rpData.propertyDetails);

  const formState = useMemo(
    () =>
      availableForms.find(form => form.type === selectedForm?.formType)?.state,
    [selectedForm]
  );

  const [searchTermValue, setSearchTermValue] = useState('');
  const [localError, setLocalError] = useState(error);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTab, setCurrentTab] = useState(Tabs.Address);
  const [step, setStep] = useState(PropertySearchSteps.Search);
  const [suggestions, setSuggestions] = useState<PropertySuggestionItem[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] =
    useState<PropertySuggestionItem | null>(null);
  const [loadPlanPropertiesSet, setLoadPlanPropertiesSet] = useState<number>(0);
  const [selectedAddress, setSelectedAddress] =
    useState<PropertyAddress | null>(null);
  const resultContainerRef = useRef<HTMLDivElement | null>(null);
  const [focusedResultIndex, setFocusedResultIndex] = useState<number>(-1);

  const canAccessRural = useCanAccessRural();

  const getSuggestions = useCallback(
    async (signal: AbortSignal, searchTerm: string) => {
      if (!getAuthToken || !formState) return;

      const authToken = await getAuthToken();

      if (!authToken) return;

      try {
        if (currentTab === Tabs.Address) {
          const result = await fetchAddresses(
            authToken,
            searchTerm,
            formState,
            signal
          );

          setSuggestions(result?.suggestions || []);
        } else if (currentTab === Tabs.LotPlan) {
          const result = await fetchPlanSuggestions(
            authToken,
            searchTerm,
            formState,
            signal
          );

          setSuggestions(result?.suggestions || []);
        }
      } catch (error) {
        if (!signal.aborted && !axios.isCancel(error)) {
          console.error(error);
        }
      }
    },
    [getAuthToken, formState]
  );

  // Define references to track ongoing requests
  const ongoingRequests = useRef<AbortController[]>([]);

  const throttledGetSuggestions = useCallback(
    throttle(async (searchTerm: string) => {
      // Aborting ongoing requests
      ongoingRequests.current.forEach((controller: AbortController) =>
        controller.abort()
      );
      ongoingRequests.current = [];

      const controller = new AbortController();
      ongoingRequests.current.push(controller);

      await getSuggestions(controller.signal, searchTerm);
    }, 100),
    [ongoingRequests, getSuggestions, currentTab]
  );

  useEffect(() => {
    setSearchTerm('');
  }, [currentTab]);

  useEffect(() => {
    setSearchTermValue(searchTerm || '');
  }, [searchTerm]);

  useEffect(() => {
    const trimmedSearchTerm = searchTerm.replace(/\s/g, '');

    if (trimmedSearchTerm.length < 3) {
      setSuggestions([]);
      return;
    }

    throttledGetSuggestions(searchTerm);
  }, [searchTerm]);

  const loadPropertyDetails = useCallback(async () => {
    if (propertyDetails.loadingState === LoadingState.Loading) return;
    if (
      !getAuthToken ||
      !selectedSuggestion?.suggestionProperties ||
      selectedSuggestion.suggestionProperties.length === 0
    )
      return;

    const propertyIds = selectedSuggestion.suggestionProperties
      .map(suggestion => suggestion.propertyId)
      .slice(loadPlanPropertiesSet * 10, (loadPlanPropertiesSet + 1) * 10);

    const authToken = await getAuthToken();

    if (!authToken) return;

    dispatch(getPropertyDetails(authToken, propertyIds));
  }, [
    propertyDetails,
    selectedSuggestion,
    getAuthToken,
    dispatch,
    loadPlanPropertiesSet,
  ]);

  useEffect(() => {
    if (
      step === PropertySearchSteps.Search &&
      propertyDetails.loadingState === LoadingState.Loading
    ) {
      setLoadPlanPropertiesSet(0);
      setStep(PropertySearchSteps.PropertyLoading);
      return;
    } else if (
      step === PropertySearchSteps.PropertyLoading &&
      propertyDetails.loadingState === LoadingState.Loaded
    ) {
      setStep(PropertySearchSteps.PropertyConfirmation);
      return;
    } else if (
      step !== PropertySearchSteps.Search &&
      propertyDetails.loadingState === LoadingState.NotLoaded
    ) {
      setStep(PropertySearchSteps.Search);
      return;
    } else if (propertyDetails.loadingState === LoadingState.Failed) {
      setStep(PropertySearchSteps.NoPropertyFound);
      return;
    }
  }, [propertyDetails]);

  useEffect(() => {
    return () => {
      dispatch(clearPropertyDetails());
    };
  }, []);

  useEffect(() => {
    if (selectedSuggestion) {
      loadPropertyDetails();
    }
  }, [selectedSuggestion, loadPlanPropertiesSet]);

  useEffect(() => {
    if (!selectedSuggestion) {
      setLoadPlanPropertiesSet(0);
    }
  }, [selectedSuggestion]);

  const wrapperClasses = clsx('wrapper', localError && 'error');
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (focusedResultIndex < suggestions.length - 1) {
          setFocusedResultIndex(focusedResultIndex + 1);
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (focusedResultIndex > 0) {
          setFocusedResultIndex(focusedResultIndex - 1);
        } else {
          setFocusedResultIndex(-1);
        }
      } else if (e.key === 'Enter') {
        e.preventDefault();
        if (
          focusedResultIndex < suggestions.length &&
          focusedResultIndex >= 0
        ) {
          setSelectedSuggestion(suggestions[focusedResultIndex]);
        } else if (focusedResultIndex === -1 && suggestions.length > 0) {
          setSelectedSuggestion(suggestions[0]);
        }
      }
    },
    [focusedResultIndex, suggestions]
  );

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      setSearchTerm('');
      setSearchTermValue('');
      setSuggestions([]);
      setSelectedSuggestion(null);
      setFocusedResultIndex(-1);
    }
  };

  const addressInput = useRef(null);

  useEffect(() => {
    if (resultContainerRef.current && focusedResultIndex >= 0) {
      const resultItems =
        resultContainerRef.current.querySelectorAll('.agxAddress-result');
      if (resultItems.length > 0) {
        const resultItem = resultItems[focusedResultIndex] as HTMLDivElement;
        resultItem.focus();
      }
    }
  }, [focusedResultIndex]);

  useEffect(() => {
    setLocalError(error);
  }, [error]);

  useEffect(() => {
    updateFormSettings({
      growContent: step === PropertySearchSteps.PropertyLoading,
    });

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (step === PropertySearchSteps.Search) {
      dispatch(clearPropertyDetails());
      setSearchTerm('');
      setSearchTermValue('');
      setSuggestions([]);
      setSelectedSuggestion(null);
      setFocusedResultIndex(-1);
    }
  }, [step]);

  const onPropertyConfirmed = useCallback(
    (allPropertyDetails: AllPropertyDetails) => {
      if (disabled) return;

      const {
        propertyDetails,
        propertyImageUrls,
        matchedAddress: address,
        vendors,
      } = allPropertyDetails;

      // Validating if it can access rural property
      if (!canAccessRural(propertyDetails.totalLandArea, address)) {
        setSelectedAddress(address);
        setStep(PropertySearchSteps.CannotAccessRural);
        return;
      }

      onBeginCampaign(address, propertyImageUrls, propertyDetails, vendors);
    },
    [disabled, canAccessRural, onBeginCampaign]
  );

  const searchKeyEntered = (value: string) => {
    setSearchTerm(value);
  };

  const placeholder = useMemo(() => {
    if (currentTab === Tabs.Address) {
      return 'Search address as it appears in RP Data';
    } else if (currentTab === Tabs.LotPlan) {
      return 'Search lot and/or plan eg. 10/SP244179';
    }
  }, [currentTab]);

  const autoCompleteBody = useMemo(() => {
    if (searchTermValue.length < 3 || suggestions.length <= 0) return;

    return suggestions.map((suggestion, index) => (
      <div
        className={clsx(
          'property-search-result',
          index === focusedResultIndex && 'focused'
        )}
        key={`suggestion-${index}`}
        onClick={() => {
          setSelectedSuggestion(suggestion);
        }}
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            setSelectedSuggestion(suggestion);
          }
        }}
        onFocus={() => setFocusedResultIndex(index)}
        onBlur={() => setFocusedResultIndex(-1)}
      >
        <Images.MapPin />
        <div className="property-search-result-detail">
          <p className="propertyAddress">{suggestion.suggestionLine1}</p>
          <p className="propertyRegion">{suggestion.suggestionLine2}</p>
        </div>
      </div>
    ));
  }, [searchTermValue, suggestions, focusedResultIndex]);

  const onManualAddressClick = useCallback(() => {
    setSearchTerm('');
    setSearchTermValue('');
    setSuggestions([]);
    setSelectedSuggestion(null);
    setLoadPlanPropertiesSet(0);
    setFocusedResultIndex(-1);
    onManualAddress();
  }, [onManualAddress]);

  const tabSelectors = [Tabs.Address, Tabs.LotPlan].map(tab => {
    const classNames = clsx(
      'tabSelector',
      'cursorPointer',
      currentTab !== tab && 'body small',
      currentTab === tab && 'ui-strong-small selected'
    );

    return (
      <div
        key={tab}
        className={classNames}
        onClick={() => {
          setCurrentTab(tab);
        }}
      >
        <AgxBodyText small>{tab}</AgxBodyText>
      </div>
    );
  });

  if (step === PropertySearchSteps.PropertyLoading)
    return <PropertySearchLoading suggestionItem={selectedSuggestion} />;

  if (step === PropertySearchSteps.PropertyConfirmation)
    return (
      <PropertyConfirmation
        confirmationId={addressId}
        currentTab={currentTab}
        suggestion={selectedSuggestion}
        onPropertyConfirmed={onPropertyConfirmed}
        onSearchAgain={() => {
          setStep(PropertySearchSteps.Search);
        }}
        onLoadMore={() => {
          setLoadPlanPropertiesSet(prevState => (prevState ?? 0) + 1);
        }}
        allPropertyDetails={propertyDetails.items}
        disabled={disabled}
        loading={propertyDetails.loadingState === LoadingState.Loading}
      />
    );

  if (step === PropertySearchSteps.NoPropertyFound) {
    return (
      <NoPropertyFound
        goToSearch={() => setStep(PropertySearchSteps.Search)}
        goToManualAddress={onManualAddressClick}
      />
    );
  }

  if (step === PropertySearchSteps.CannotAccessRural) {
    return (
      <CannotAccessRural
        address={selectedAddress}
        onCancel={() => {
          navigate('/');
        }}
        onSearchAgain={() => {
          setStep(PropertySearchSteps.Search);
        }}
      />
    );
  }

  return (
    <>
      <PageTitle
        onClick={() => void 0}
        title="Search for Property"
        breadcrumbsAvailable={false}
      />
      <div key={addressId} className="propertySearchAutoCompleteContainer">
        <div className="textInput">
          {label && (
            <div className="header">
              {label && (
                <AgxLabel medium inverse={inverseLabel}>
                  {label}
                </AgxLabel>
              )}
            </div>
          )}
        </div>
        <div
          className={classAugmenter(
            'textInput addressDropdown dark stretch',
            localError,
            disabled
          )}
          ref={addressInput}
        >
          <div className={wrapperClasses}>
            <Images.SearchOutline />
            <input
              className="field inputText medium"
              id={addressId}
              data-testid={`agxAutoAddress-${addressId}`}
              onChange={({ target: { value } }) => searchKeyEntered(value)}
              placeholder={placeholder}
              value={searchTermValue}
              disabled={disabled}
              autoComplete="off"
              inputMode="search"
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
            />
          </div>
          <div className="tabsContainer">
            <AgxRow largeGap>{tabSelectors}</AgxRow>
          </div>
          {localError && (
            <div className="errorMessage">
              <Images.AlertCircle />
              <AgxBodyText small extraClasses="error">
                {localError}
              </AgxBodyText>
            </div>
          )}
          <div className="property-search-results">{autoCompleteBody}</div>
        </div>
      </div>
      <AgxBodyText medium inverse extraClasses="enterAddressManuallyText">
        Can't find what you're looking for?{' '}
        <button
          className="enterAddressManuallyLink"
          onClick={() => {
            if (!disabled) {
              onManualAddress();
            }
          }}
        >
          Enter address manually
        </button>
      </AgxBodyText>
    </>
  );
};

export default PropertySearch;
