import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  AgxButton,
  AgxBodyText,
  Images,
  AgxColumn,
  AgxRow,
  AgxRadio,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { campaignsApi } from 'Api/Campaigns/campaignsApi';
import { LoadingState } from 'utils/loadingState';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { ReactComponent as AlertIcon } from './assets/icons/alert-triangleOutline.svg';
import { ReactComponent as CheckmarkIcon } from './assets/icons/checkmark-circleOutline.svg';
import { setAndShowErrorToast } from 'store/config';
import './PoolSafetyCertificate.scss';

interface PoolSafetyCertificateProps {
  id: string;
  onValueChanged: (value: { id: string; value: any }) => void;
  defaultValue: any;
  validate: boolean;
}

const PoolSafetyType = {
  HasPool: 'HasPool',
  NoPool: 'NoPool',
};

const expiryWarningThreshold = 60;

const CertificateRequestType = {
  VendorWillSupply: 'VendorWillSupply',
  RequestNoticeOfNoPoolSafety: 'RequestNoticeOfNoPoolSafety',
  DoNotRequest: 'DoNotRequest',
};

const PoolSafetyCertificate = ({
  id,
  onValueChanged,
  defaultValue,
  validate,
}: PoolSafetyCertificateProps) => {
  const [, getAuthToken] = useAzureAuth();
  const {
    loadingState: poolCertLoadingState,
    poolSafetyCertificate,
    poolBuildingCertificate,
  } = useAppSelector(state => state.poolDocumentation);

  const [poolSafetyType, setPoolSafetyType] = useState(
    defaultValue?.poolSafety
  );
  const [certificateRequestType, setCertificateRequestType] = useState(
    defaultValue?.certificateRequest
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    const poolDocumentation = {
      poolSafety: poolSafetyType,
      certificateRequest:
        poolSafetyType === PoolSafetyType.HasPool
          ? certificateRequestType
          : null,
      poolSafetyCertificate:
        poolSafetyType === PoolSafetyType.HasPool
          ? poolSafetyCertificate
          : null,
      poolBuildingCertificate:
        poolSafetyType === PoolSafetyType.HasPool
          ? poolBuildingCertificate
          : null,
      noticeOfNoPoolCertificate:
        poolSafetyType === PoolSafetyType.HasPool &&
        certificateRequestType ===
          CertificateRequestType.RequestNoticeOfNoPoolSafety
          ? defaultValue?.noticeOfNoPoolCertificate
          : null,
    };
    onValueChanged({ id, value: poolDocumentation });
  }, [
    poolSafetyCertificate,
    poolBuildingCertificate,
    poolSafetyType,
    certificateRequestType,
  ]);

  const certificateExpiresInDays =
    poolSafetyCertificate?.hasCertificate &&
    poolSafetyCertificate?.expiryDate != null
      ? moment(poolSafetyCertificate.expiryDate).diff(moment(), 'days')
      : poolBuildingCertificate?.hasCertificate &&
          poolBuildingCertificate?.expiryDate != null
        ? moment(poolBuildingCertificate.expiryDate).diff(moment(), 'days')
        : null;

  const addPoolCertificateBody = (
    <div className="agx-radio">
      <label className="agx-radio-label">What would you like to do?</label>
      <div className="agx-radio-group">
        <AgxRadio
          id="vendorWillSupplyCert"
          label="Vendor will supply new Safety Certificate"
          onCheckChanged={() =>
            setCertificateRequestType(CertificateRequestType.VendorWillSupply)
          }
          checked={
            certificateRequestType === CertificateRequestType.VendorWillSupply
          }
        />
        <AgxRadio
          id="requestNoticeOfNoPoolCert"
          label="Request Form 36: Notice of No Pool Safety Certificate"
          onCheckChanged={() =>
            setCertificateRequestType(
              CertificateRequestType.RequestNoticeOfNoPoolSafety
            )
          }
          checked={
            certificateRequestType ===
            CertificateRequestType.RequestNoticeOfNoPoolSafety
          }
        />
        {certificateExpiresInDays && (
          <AgxRadio
            id="doNotRequest"
            label="Don't request"
            onCheckChanged={() =>
              setCertificateRequestType(CertificateRequestType.DoNotRequest)
            }
            checked={
              certificateRequestType === CertificateRequestType.DoNotRequest
            }
          />
        )}
        {validate && !certificateRequestType && (
          <div className="errorMessage">
            <Images.AlertCircle />
            <AgxBodyText small extraClasses="error">
              {'Select an option'}
            </AgxBodyText>
          </div>
        )}
      </div>
    </div>
  );

  const downloadDocument = async () => {
    if (!getAuthToken || !poolSafetyCertificate) return;

    const authToken = await getAuthToken();

    if (!authToken) return;

    const { containerFilePath } = poolSafetyCertificate.certificate ?? {};

    if (!containerFilePath) return;

    try {
      const { data: fileLink } = await campaignsApi(authToken).get<string>(
        'GetUploadedFileLink',
        {
          filePath: containerFilePath,
        }
      );

      if (fileLink) {
        window.open(fileLink);
      }
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      return err.message;
    }
  };

  const getPoolCertComponent = (): React.ReactNode | undefined => {
    if (poolSafetyType !== PoolSafetyType.HasPool) return undefined;

    if (poolCertLoadingState === LoadingState.Loading) {
      return <React.Fragment key="loading">Loading...</React.Fragment>;
    } else if (
      poolCertLoadingState === LoadingState.Loaded &&
      (poolSafetyCertificate?.hasCertificate ||
        poolBuildingCertificate?.hasCertificate)
    ) {
      const icon =
        certificateExpiresInDays &&
        certificateExpiresInDays < expiryWarningThreshold ? (
          <AlertIcon className="agx-pool-icon" />
        ) : (
          <CheckmarkIcon className="agx-pool-icon" />
        );

      const label = poolSafetyCertificate && (
        <AgxBodyText medium>
          {poolSafetyCertificate.hasCertificate ? 'Pool' : 'Building'}{' '}
          certificate will expire in {certificateExpiresInDays} days
        </AgxBodyText>
      );

      return (
        <AgxRow fill largeGap key="viewPoolCert">
          {icon}
          <AgxColumn fill largeGap extraClasses="viewPoolCert">
            {label}
            {poolSafetyCertificate?.hasCertificate && (
              <AgxButton
                id="viewPoolCertificate"
                text="View pool certificate"
                primary
                small
                onClick={(): void => {
                  downloadDocument();
                }}
                rightIcon={<Images.ExternalLinkOutline />}
              />
            )}
          </AgxColumn>
        </AgxRow>
      );
    } else {
      return (
        <AgxColumn fill extraLargeGap key="noCert">
          <AgxRow largeGap centered>
            <AlertIcon className="agx-pool-alertIcon" />
            <AgxBodyText medium>No Pool Certificate found</AgxBodyText>
          </AgxRow>
          {addPoolCertificateBody}
        </AgxColumn>
      );
    }
  };

  return (
    <div className="agx-radio">
      <label className="agx-radio-label">Pool Safety</label>
      <div className="agx-radio-group">
        <AgxRadio
          id="hasPool"
          label="Has Pool or Spa"
          onCheckChanged={() => setPoolSafetyType(PoolSafetyType.HasPool)}
          checked={poolSafetyType === PoolSafetyType.HasPool}
          subContent={
            poolSafetyType === PoolSafetyType.HasPool
              ? [getPoolCertComponent()]
              : []
          }
        />
        <AgxRadio
          id="noPool"
          label="No Pool"
          onCheckChanged={() => setPoolSafetyType(PoolSafetyType.NoPool)}
          checked={poolSafetyType === PoolSafetyType.NoPool}
        />
      </div>
      {validate && !poolSafetyType && (
        <div className="errorMessage">
          <Images.AlertCircle />
          <AgxBodyText small extraClasses="error">
            {'Pool Safety selection required'}
          </AgxBodyText>
        </div>
      )}
    </div>
  );
};

export default PoolSafetyCertificate;
