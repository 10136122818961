import { useMemo } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  AgxColumn,
  AgxHeader,
  AgxBodyText,
  AgxMultiOptionButton,
  Images,
  AgxLabel,
  DocumentTypes,
  DocumentTypesMap,
} from '@urbanx/agx-ui-components';
import placeHolderImage from 'assets/images/placeholder-property.png';
import { useGetFileLink } from 'components/ui-components/File/fileApi';
import { Breakpoints, ScreenSize } from 'utils/screen';
import { prepareSignatories } from 'utils/vendorBuyerUtil';
import { cleanTwoLineAddress } from 'utils/formatAddress';
import { formatListOfStrings } from 'utils/formatString';
import { formatCurrency } from 'utils/formatNumber';
import { useSelectedContract } from 'hooks/useSelectedContract';
import { selectContract } from '../../../../../../campaigns/campaignsReducer';
import FormDetailsHeader from '../../Common/FormDetailsHeader';

const ContractSubmitPanel = ({ campaign, contract }) => {
  const dispatch = useDispatch();
  const { lastUpdate, buyers, documents, purchasePrice } =
    useSelectedContract();
  const { address, propertyImageUrls: { medium: imageUrl = null } = {} } =
    campaign;

  const headerDetail = `Submitted ${moment(lastUpdate).format('DD/MM/YY')}`;

  const [addressLineOne, addressLineTwo] = cleanTwoLineAddress(address);
  const isMobile = ScreenSize() === Breakpoints.Mobile;

  const allBuyers = prepareSignatories(buyers, 'Buyer');

  const getFileLink = useGetFileLink();

  const mergedSalesContract = documents?.filter(
    document => document.documentType === DocumentTypes.MergedSalesContract
  );

  const allBuyerNames = allBuyers
    .map(item => {
      if (item.type === 'Buyer') return item.name;
      if (item.type === 'Attorney') return item.principalName;
      if (item.type === 'Signatory') return item.companyName;

      return item;
    })
    .filter(name => name !== undefined);

  const fileDownloadOptions = useMemo(() => {
    if (documents?.length === 0) return [];
    const options = [];

    if (mergedSalesContract && mergedSalesContract.length > 0) {
      options.push({
        text: 'Download All',
        onClick: async () => {
          const fileLink = await getFileLink(
            mergedSalesContract[0].containerFilePath
          );
          window.open(fileLink);
        },
      });
    }

    if (documents?.length > 0) {
      documents.forEach(document => {
        if (document.documentType !== DocumentTypes.MergedSalesContract) {
          options.push({
            text: DocumentTypesMap[document.documentType],
            onClick: async () => {
              const fileLink = await getFileLink(document.containerFilePath);
              window.open(fileLink);
            },
          });
        }
      });
    }

    // return unique document list by text property
    return [...new Map(options.map(item => [item.text, item])).values()];
  }, [documents, getFileLink]);

  const renderIcon = () => {
    return (
      <AgxColumn centered>
        <Images.SignedByAll />
        <AgxLabel small colour="neutral-grey-700">
          {contract.contractStage}
        </AgxLabel>
      </AgxColumn>
    );
  };

  return (
    <>
      <FormDetailsHeader
        formName="Contract"
        detail={headerDetail}
        onBack={() => dispatch(selectContract(null))}
      />

      <AgxColumn veryLargeGap fill centered>
        {renderIcon()}
        <AgxHeader size={2} centered>
          {addressLineOne},
          <br />
          {addressLineTwo}
        </AgxHeader>
        <AgxBodyText medium centered>
          {formatListOfStrings(allBuyerNames)} •{' '}
          {formatCurrency(purchasePrice, 0)}
        </AgxBodyText>
        <img
          className="propertyImage"
          src={imageUrl ?? placeHolderImage}
          alt="Property"
        />
        {fileDownloadOptions?.length > 0 && (
          <AgxMultiOptionButton
            id="viewContractSubmissionPdfs"
            text="View PDF"
            onClick={async () => {
              const fileLink = await getFileLink(
                mergedSalesContract.containerFilePath
              );
              window.open(fileLink);
            }}
            hollow
            medium
            rightIcon={<Images.FileTextOutline />}
            wide={isMobile}
            options={fileDownloadOptions}
          />
        )}
      </AgxColumn>
    </>
  );
};

export default ContractSubmitPanel;
