import clsx from 'clsx';
import { AgxRow, lowerCaseFirstLetter } from '@urbanx/agx-ui-components';
import './CampaignTabs.scss';

export const CampaignsTabs = ({ tabs = [], selectedTab, tabChanged }) => {
  const tabSelectors = tabs.map(tab => {
    const classNames = clsx(
      'campaignsTab',
      'cursorPointer',
      selectedTab !== tab && 'body small',
      selectedTab === tab && 'ui-strong-small selected'
    );

    return (
      <div
        data-testid={`agx-${lowerCaseFirstLetter(tab)}`}
        key={tab}
        className={classNames}
        onClick={() => {
          tabChanged(tab);
        }}
      >
        {tab}
      </div>
    );
  });

  return (
    <div className="campaignTabsContainer">
      <AgxRow largeGap>{tabSelectors}</AgxRow>
    </div>
  );
};
