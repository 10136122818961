import { PropertyAddress } from '@urbanx/agx-ui-components';

export const cleanFullAddress = (property: PropertyAddress | null): string => {
  if (!property) return 'Address undefined';

  const { unitNumber, streetAddress, suburb, state, postalCode } = property;

  return concatenateAddress([
    unitNumber,
    streetAddress,
    suburb,
    state,
    postalCode,
  ]);
};

export const cleanTwoLineAddress = (
  property: PropertyAddress | null
): string[] => {
  if (!property) return ['Address undefined', ''];

  const { unitNumber, streetAddress, suburb, state, postalCode } = property;

  return [
    concatenateAddress([unitNumber, streetAddress], '/'),
    concatenateAddress([suburb, state, postalCode]),
  ];
};

export const cleanTwoLineAddressWithoutState = (
  property: PropertyAddress | null
): string[] => {
  if (!property) return ['Address undefined', ''];

  const { unitNumber, streetAddress, suburb, postalCode } = property;

  return [
    concatenateAddress([unitNumber, streetAddress], '/'),
    concatenateAddress([suburb, postalCode]),
  ];
};

export const cleanStreetAddress = (
  property: PropertyAddress | null,
  joiner: string = ' '
): string | null => {
  if (!property) return null;

  const { unitNumber, streetAddress, suburb } = property;

  const combinedStreetAddress = concatenateAddress(
    [unitNumber, streetAddress + ','],
    '/'
  );

  return concatenateAddress([combinedStreetAddress, suburb], joiner);
};

export const cleanShortAddress = (
  property: PropertyAddress | null
): string | null => {
  if (!property) return null;

  const { unitNumber, streetAddress } = property;

  return concatenateAddress([unitNumber, streetAddress], '/');
};

export const concatenateAddress = (
  elements: (string | undefined)[],
  joiner: string = ' '
): string => {
  return elements.filter(e => e != null && e !== '').join(joiner);
};
