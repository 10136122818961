import React from 'react';
import {
  AgxDivider,
  AgxColumn,
  UploadedPOADocumentModel,
  POADocumentConfig,
  UploadedDocument,
  DocumentTypes,
} from '@urbanx/agx-ui-components';
import FileUpload from '../FileUpload/FileUpload';
import isEqual from 'lodash.isequal';
import { useAppSelector } from 'hooks/useAppSelector';

interface POADocumentUploadProps {
  id: string;
  title: string;
  pOADocumentConfig: POADocumentConfig[];
  defaultValue?: UploadedPOADocumentModel[] | null;
  onValueChanged: (value: { id: string; value: any }) => void;
  validate: boolean;
  required: boolean;
}
const POADocumentUpload = ({
  id,
  pOADocumentConfig,
  defaultValue,
  onValueChanged,
  validate,
  required,
}: POADocumentUploadProps) => {
  const { campaignId } = useAppSelector(state => state.form);
  const pOADocuments = defaultValue ?? [];
  const updatePOADocumentDetail = (
    updatedPOADocument: UploadedDocument,
    vendorId: string
  ) => {
    let updatedPOADocuments = pOADocuments
      .map(d =>
        d.vendorId === vendorId
          ? ((updatedPOADocument
              ? { ...d, ...updatedPOADocument }
              : null) as UploadedPOADocumentModel)
          : d
      )
      .filter(d => d != null);

    if (
      !updatedPOADocuments.some(d => d.vendorId === vendorId) &&
      updatedPOADocument
    ) {
      updatedPOADocuments = [
        ...updatedPOADocuments,
        { vendorId, ...updatedPOADocument },
      ];
    }
    const sortByFileName = (docs: UploadedPOADocumentModel[]) =>
      [...docs].sort((a, b) => {
        if (a.fileName && b.fileName) {
          return a.fileName.localeCompare(b.fileName);
        }
        if (a.fileName) return -1;
        if (b.fileName) return 1;
        return 0;
      });

    const sortedUpdatedPOADocuments = sortByFileName(updatedPOADocuments);
    const sortedPOADocuments = sortByFileName(pOADocuments);

    if (!isEqual(sortedUpdatedPOADocuments, sortedPOADocuments)) {
      onValueChanged({ id, value: updatedPOADocuments });
    }
  };

  return (
    <AgxColumn extraLargeGap>
      {pOADocumentConfig?.map((file, index) => {
        const { description, vendorId } = file;

        const matchingPOADocuments = pOADocuments.filter(
          doc => doc.vendorId === vendorId
        );

        return (
          <React.Fragment key={index}>
            {index !== 0 ? <AgxDivider expanded /> : <></>}
            <FileUpload
              key={index}
              id={index}
              documentType={DocumentTypes.PowerOfAttorney}
              title={description}
              fileData={matchingPOADocuments}
              campaignId={campaignId}
              onValueChanged={({ value }: { value: UploadedDocument[] }) =>
                updatePOADocumentDetail(value[0], vendorId)
              }
              multiple={false}
              validate={validate}
              required={required}
            />
          </React.Fragment>
        );
      })}
    </AgxColumn>
  );
};

export default POADocumentUpload;
