import {
  AgxRow,
  AgxLabel,
  AgxRadio,
  AgxTextInput,
  AgxColumn,
} from '@urbanx/agx-ui-components';
import React, { useState, useEffect } from 'react';
import './WaiverOfCoolingOff.scss';

const CoolingOffCondition = {
  IncludeWaiverOfCoolingOff: 'IncludeWaiverOfCoolingOff',
  IncludeShorteningOfCoolingOff: 'IncludeShorteningOfCoolingOff',
};

const WaiverOfCoolingOff = ({ id, defaultValue, onValueChanged, validate }) => {
  const [localError, setLocalError] = useState();
  const [waiverOfCoolingOff, setWaiverOfCoolingOff] = useState({
    coolingOffCondition: defaultValue?.coolingOffCondition,
    shortenCoolingOffDays: defaultValue?.shortenCoolingOffDays,
  });

  useEffect(() => {
    onValueChanged({
      ...waiverOfCoolingOff,
    });
  }, [waiverOfCoolingOff]);

  useEffect(() => {
    if (validate) {
      if (
        waiverOfCoolingOff.coolingOffCondition ===
        CoolingOffCondition.IncludeShorteningOfCoolingOff
      ) {
        if (parseInt(waiverOfCoolingOff.shortenCoolingOffDays) > 4)
          setLocalError("Days can't exceed 4 days.");
        else if (!waiverOfCoolingOff.shortenCoolingOffDays)
          setLocalError('Enter days.');
        else setLocalError();
      }
    }
  }, [validate, waiverOfCoolingOff.shortenCoolingOffDays]);

  return (
    <AgxColumn mediumGap fill>
      <AgxRow fill spaceBetween>
        <AgxLabel medium>Waiver/Shortening of cooling off</AgxLabel>
      </AgxRow>
      <div className="agxRadio-group">
        <AgxRadio
          id="NotRequired"
          label="Not Required"
          onCheckChanged={() =>
            setWaiverOfCoolingOff({
              ...waiverOfCoolingOff,
              coolingOffCondition: null,
              shortenCoolingOffDays: null,
            })
          }
          checked={
            waiverOfCoolingOff.coolingOffCondition !==
              CoolingOffCondition.IncludeShorteningOfCoolingOff &&
            waiverOfCoolingOff.coolingOffCondition !==
              CoolingOffCondition.IncludeWaiverOfCoolingOff
          }
        />
        <AgxRadio
          id="NotRequired"
          label="Include Waiver of Cooling Off"
          onCheckChanged={() =>
            setWaiverOfCoolingOff({
              ...waiverOfCoolingOff,
              coolingOffCondition:
                CoolingOffCondition.IncludeWaiverOfCoolingOff,
              shortenCoolingOffDays: null,
            })
          }
          checked={
            waiverOfCoolingOff.coolingOffCondition ===
            CoolingOffCondition.IncludeWaiverOfCoolingOff
          }
        />
        <AgxRadio
          id="IncludeShorteningOfCoolingOff"
          label="Include Shortening of Cooling Off"
          onCheckChanged={() =>
            setWaiverOfCoolingOff({
              ...waiverOfCoolingOff,
              coolingOffCondition:
                CoolingOffCondition.IncludeShorteningOfCoolingOff,
            })
          }
          checked={
            waiverOfCoolingOff.coolingOffCondition ===
            CoolingOffCondition.IncludeShorteningOfCoolingOff
          }
          subContent={
            waiverOfCoolingOff.coolingOffCondition ===
              CoolingOffCondition.IncludeShorteningOfCoolingOff && [
              <AgxColumn mediumGap>
                <AgxLabel medium>Shorten cooling off period to</AgxLabel>
                <AgxColumn extraClasses={'days'}>
                  <AgxTextInput
                    id="ShorteningOfCoolingOffDays"
                    small
                    noHeader
                    numberOnly
                    noOptionalLabel
                    maxLength="2"
                    value={waiverOfCoolingOff.shortenCoolingOffDays}
                    defaultValue={waiverOfCoolingOff.shortenCoolingOffDays}
                    onInputValueChange={value =>
                      setWaiverOfCoolingOff({
                        ...waiverOfCoolingOff,
                        shortenCoolingOffDays: value.value,
                      })
                    }
                    rightIcon={<AgxLabel>day(s)</AgxLabel>}
                    error={localError}
                  />
                </AgxColumn>
              </AgxColumn>,
            ]
          }
        />
      </div>
    </AgxColumn>
  );
};

export default WaiverOfCoolingOff;
