import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CampaignCompletionState,
  CampaignDetailModel,
} from '@urbanx/agx-ui-components';
import { setAndShowSuccessToast, setAndShowErrorToast } from 'store/config';
import { LoadingState } from 'utils/loadingState';
import { AppDispatch, RootState } from 'store/store';
import { campaignsApi } from 'Api/Campaigns/campaignsApi';
import {
  CampaignActionRequest,
  CampaignActionResponse,
  CampaignStageAction,
} from 'Api/Campaigns/Types/campaignTypes';

interface CampaignsState {
  selectedCampaignId: string | null;
  selectedContractId: string | null;
  loadingState: LoadingState;
  archivedLoadingState: LoadingState;
  campaigns: CampaignDetailModel[];
  archivedCampaigns: CampaignDetailModel[];
}

const initialState = {
  selectedCampaignId: null,
  selectedContractId: null,
  loadingState: LoadingState.NotLoaded,
  archivedLoadingState: LoadingState.NotLoaded,
  campaigns: [],
  archivedCampaigns: [],
} satisfies CampaignsState as CampaignsState;

const slice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    clearLoadingState: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.NotLoaded,
        archivedLoadingState: LoadingState.NotLoaded,
      };
    },
    startLoadingCampaigns: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Loading,
      };
    },
    startLoadingArchivedCampaigns: state => {
      return {
        ...state,
        ...initialState,
        archivedLoadingState: LoadingState.Loading,
      };
    },
    campaignsLoaded: (state, action: PayloadAction<CampaignDetailModel[]>) => {
      const campaigns = action.payload;

      const selectedCampaignId =
        state.selectedCampaignId == null && campaigns?.length > 0
          ? campaigns[0].campaignId
          : state.selectedCampaignId;

      return {
        ...state,
        campaigns: [...campaigns],
        loadingState: LoadingState.Loaded,
        selectedCampaignId: selectedCampaignId,
        selectedContractId: null,
      };
    },
    archivedCampaignsLoaded: (
      state,
      action: PayloadAction<CampaignDetailModel[]>
    ) => {
      const campaigns = action.payload;

      return {
        ...state,
        archivedCampaigns: [...campaigns],
        archivedLoadingState: LoadingState.Loaded,
      };
    },
    errorLoadingCampaigns: state => {
      return {
        ...state,
        ...initialState,
        loadingState: LoadingState.Failed,
      };
    },
    setSelectedCampaignId: (state, action: PayloadAction<string | null>) => {
      const campaignId = action.payload;

      return {
        ...state,
        selectedCampaignId: campaignId,
        selectedContractId: null,
      };
    },
    setSelectedContractId: (state, action: PayloadAction<string | null>) => {
      const contractId = action.payload;

      return {
        ...state,
        selectedContractId: contractId,
      };
    },
    markCampaignAsViewed: (
      state,
      action: PayloadAction<CampaignDetailModel>
    ) => {
      const campaign = action.payload;

      const campaigns = state.campaigns.map(c => {
        if (c.campaignId === campaign.campaignId) {
          return {
            ...c,
            read: true,
          };
        }

        return {
          ...c,
        };
      });

      return {
        ...state,
        campaigns: campaigns,
      };
    },
    markArchiveCampaignAsViewed: (
      state,
      action: PayloadAction<CampaignDetailModel>
    ) => {
      const campaign = action.payload;

      const archivedCampaigns = state.archivedCampaigns.map(c => {
        if (c.campaignId === campaign.campaignId) {
          return {
            ...c,
            read: true,
          };
        }

        return {
          ...c,
        };
      });

      return {
        ...state,
        archivedCampaigns: archivedCampaigns,
      };
    },
    markCampaignsAsStale: state => {
      return {
        ...state,
        loadingState: LoadingState.NotLoaded,
      };
    },
    campaignArchived: (
      state,
      action: PayloadAction<{
        archivedCampaignId: string;
        completionState: CampaignCompletionState;
      }>
    ) => {
      const { archivedCampaignId, completionState } = action.payload;

      const campaignBeingArchived = state.campaigns.find(
        campaign => campaign.campaignId === archivedCampaignId
      );

      if (!campaignBeingArchived) return state;

      const updatedCampaigns = state.campaigns.filter(
        campaign => campaign.campaignId !== archivedCampaignId
      );

      const selectedCampaignId =
        (updatedCampaigns?.length ?? -1) > 0
          ? updatedCampaigns[0].campaignId
          : null;

      return {
        ...state,
        campaigns: updatedCampaigns,
        archivedCampaigns: [
          ...state.archivedCampaigns,
          { ...campaignBeingArchived, completionState: completionState },
        ],
        selectedCampaignId: selectedCampaignId,
        selectedContractId: null,
      };
    },
    campaignRestored: (
      state,
      action: PayloadAction<{
        restoredCampaignId: string;
        completionState: CampaignCompletionState;
      }>
    ) => {
      const { restoredCampaignId, completionState } = action.payload;

      const campaignBeingRestored = state.archivedCampaigns.find(
        campaign => campaign.campaignId === restoredCampaignId
      );

      if (!campaignBeingRestored) return state;

      const updatedArchivedCampaigns = state.archivedCampaigns.filter(
        campaign => campaign.campaignId !== restoredCampaignId
      );

      const selectedCampaignId =
        updatedArchivedCampaigns?.length > 0
          ? updatedArchivedCampaigns[0].campaignId
          : null;

      return {
        ...state,
        campaigns: [
          ...state.campaigns,
          { ...campaignBeingRestored, completionState: completionState },
        ],
        archivedCampaigns: updatedArchivedCampaigns,
        selectedCampaignId: selectedCampaignId,
        selectedContractId: null,
      };
    },
    updateCampaignDetail: (
      state,
      action: PayloadAction<CampaignDetailModel>
    ) => {
      const updatedCampaign = action.payload;

      const updatedCampaigns = state.campaigns.map(c => {
        if (c.campaignId === updatedCampaign.campaignId) {
          return updatedCampaign;
        }

        return c;
      });

      return {
        ...state,
        campaigns: updatedCampaigns,
      };
    },
  },
});

export default slice.reducer;

const {
  clearLoadingState,
  startLoadingCampaigns,
  startLoadingArchivedCampaigns,
  campaignsLoaded,
  archivedCampaignsLoaded,
  errorLoadingCampaigns,
  setSelectedCampaignId,
  setSelectedContractId,
  markCampaignAsViewed,
  markArchiveCampaignAsViewed,
  markCampaignsAsStale,
  campaignArchived,
  campaignRestored,
  updateCampaignDetail,
} = slice.actions;

export { markCampaignsAsStale };

export const reloadCampaigns = () => async (dispatch: AppDispatch) => {
  dispatch(clearLoadingState());
};

export const fetchAllCampaigns =
  (authToken: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingCampaigns());
      const { data: campaigns } =
        await campaignsApi(authToken).get<CampaignDetailModel[]>(
          'GetActiveCampaigns'
        );

      dispatch(campaignsLoaded(campaigns));
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      dispatch(errorLoadingCampaigns());
    }
  };

export const selectCampaign =
  (campaignId: string | null) => (dispatch: AppDispatch) => {
    dispatch(setSelectedCampaignId(campaignId));
  };

export const selectContract =
  (contractId: string | null) => (dispatch: AppDispatch) => {
    dispatch(setSelectedContractId(contractId));
  };

export const campaignHasBeenViewed =
  (campaign: CampaignDetailModel) => (dispatch: AppDispatch) => {
    dispatch(markCampaignAsViewed(campaign));
  };

export const archiveCampaignHasBeenViewed =
  (campaign: CampaignDetailModel) => (dispatch: AppDispatch) => {
    dispatch(markArchiveCampaignAsViewed(campaign));
  };

export const removeFormSubmission = async (
  authToken: string,
  campaignId: string,
  formSubmissionId: string,
  formId: string | null = null
) => {
  await campaignsApi(authToken).post('RemoveFormSubmission', {
    campaignId,
    formSubmissionId,
    formId,
  });
};

export const getArchivedCampaigns =
  (authToken: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingArchivedCampaigns());
      const { data: archivedCampaigns } = await campaignsApi(authToken).get<
        CampaignDetailModel[]
      >('GetArchivedCampaignsByAgency');

      dispatch(archivedCampaignsLoaded(archivedCampaigns));
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      dispatch(errorLoadingCampaigns());
    }
  };

export const performCampaignAction =
  (authToken: string, actionRequest: CampaignActionRequest) =>
  async (dispatch: AppDispatch) => {
    try {
      const {
        data: { campaignId: restoredCampaignId, completionState },
      } = await campaignsApi(authToken).post<CampaignActionResponse>(
        'PerformCampaignAction',
        actionRequest
      );

      if (actionRequest.campaignAction === CampaignStageAction.Archive) {
        dispatch(
          campaignArchived({
            archivedCampaignId: restoredCampaignId,
            completionState: completionState,
          })
        );
        dispatch(setAndShowSuccessToast('Campaign archived successfully!'));
      } else if (actionRequest.campaignAction === CampaignStageAction.Restore) {
        dispatch(
          campaignRestored({
            restoredCampaignId,
            completionState: completionState,
          })
        );
        dispatch(setAndShowSuccessToast('Campaign restored successfully!'));
      }
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
    }
  };

// Updates a campaign in the store with the latest data from the server
// only when campaigns are in a loaded state and the campaign exists in the store
export const updateCampaign =
  (authToken: string, campaignId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const campaignsStore = getState().campaigns;
      const campaignsLoaded =
        campaignsStore.loadingState === LoadingState.Loaded;
      const existingCampaign = campaignsStore.campaigns.find(
        c => c.campaignId === campaignId
      );

      if (!campaignsLoaded || !existingCampaign) return;

      const { data: campaignDetail } = await campaignsApi(
        authToken
      ).get<CampaignDetailModel>('GetCampaign', { campaignId: campaignId });

      dispatch(updateCampaignDetail(campaignDetail));
    } catch (err: any) {
      console.error(err);
    }
  };
