import React, { useState, useEffect } from 'react';
import {
  AgxTextInput,
  AgxCaption,
  AgxInputText,
  AgxLabel,
  AgxColumn,
  AgxRow,
  Images,
  AgxBodyText,
} from '@urbanx/agx-ui-components';
import { parseFloatReturnEmptyIfZero } from 'utils/parseFloatReturnEmptyIfZero';
import './VendorChoiceCommission.scss';

const VendorChoiceCommission = ({
  id,
  onValueChanged,
  defaultValue,
  showCommissionRangeDisclaimer,
  validate: validatePage,
}) => {
  const [minCommission, setMinCommission] = useState(
    defaultValue?.minCommission
  );
  const [maxCommission, setMaxCommission] = useState(
    defaultValue?.maxCommission
  );
  const [error, setError] = useState(null);

  const validate = () => {
    if (Number(minCommission) === 0 && Number(maxCommission) > 0) {
      setError('Min Commission must not be empty');
    } else if (
      Number(minCommission) > 0 &&
      Number(maxCommission) > 0 &&
      Number(maxCommission) <= Number(minCommission)
    ) {
      setError(`Max commission must be greater than ${minCommission}`);
    } else if (Number(maxCommission) === 0 && Number(minCommission)) {
      setError(null);
    } else {
      setError(null);
    }
  };

  useEffect(() => {
    validate();
    onValueChanged({
      id,
      value: {
        minCommission: !minCommission ? 0.0 : minCommission,
        maxCommission: !maxCommission ? 0.0 : maxCommission,
      },
    });
  }, [minCommission, maxCommission]);

  return (
    <>
      <AgxColumn fill largeGap>
        <AgxColumn fill smallGap>
          <div className="agx-minMaxCommission">
            <AgxLabel medium extraClasses="min">
              Min Commission
            </AgxLabel>
            <AgxLabel medium extraClasses="max">
              Max Commission
            </AgxLabel>
          </div>
          <div className="agx-minMaxCommission">
            <AgxTextInput
              id="minCommission"
              noHeader
              percent
              onInputValueChange={({ value }) => setMinCommission(value)}
              defaultValue={parseFloatReturnEmptyIfZero(minCommission)}
              error={
                validatePage && !parseFloatReturnEmptyIfZero(minCommission)
              }
              hideErrorDescription
              stretch
            />
            <AgxInputText>to</AgxInputText>
            <AgxTextInput
              id="maxCommission"
              noHeader
              percent
              onInputValueChange={({ value }) => setMaxCommission(value)}
              defaultValue={parseFloatReturnEmptyIfZero(maxCommission)}
              error={
                validatePage && !parseFloatReturnEmptyIfZero(maxCommission)
              }
              hideErrorDescription
              stretch
            />
          </div>
          {error && (
            <AgxRow fill mediumGap>
              <Images.AlertCircle />
              <AgxBodyText small extraClasses="error">
                {error}
              </AgxBodyText>
            </AgxRow>
          )}
        </AgxColumn>
        {showCommissionRangeDisclaimer ? (
          <AgxCaption>
            Between {Number(minCommission) || 0}% and{' '}
            {Number(maxCommission) || 0}% of the selling price to be determined
            by the Principal
          </AgxCaption>
        ) : (
          <AgxCaption>
            A fee range of between {Number(minCommission) || 0}% and{' '}
            {Number(maxCommission) || 0}% inclusive of GST of the actual sale
            price to be determined by the client once the sale contract has
            become unconditional. It is to be based on your level of
            satisfaction with the level of service experience and result we
            achieve.
          </AgxCaption>
        )}
      </AgxColumn>
    </>
  );
};

export default VendorChoiceCommission;
