import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { campaignsApi } from 'Api/Campaigns/campaignsApi';
import { useAzureAuth } from 'hooks/useAzureAuth';
import { setAndShowErrorToast } from './config';
import { AppDispatch } from './store';

interface FeatureToggles {
  generateSalesContracts: boolean;
}

interface AgentInfoState {
  agentId: string | null;
  agencyId: string | null;
  featureToggles: FeatureToggles;
}

const initialState = {
  agentId: null,
  agencyId: null,
  featureToggles: { generateSalesContracts: false },
} satisfies AgentInfoState as AgentInfoState;

const slice = createSlice({
  name: 'agentInfo',
  initialState: initialState,
  reducers: {
    agentUpdate: (
      state,
      action: PayloadAction<{
        agent: string | null;
        agency: string | null;
      }>
    ) => {
      return {
        ...state,
        agentId: action.payload.agent,
        agencyId: action.payload.agency,
      };
    },
    featureTogglesLoaded: (state, action: PayloadAction<FeatureToggles>) => {
      return {
        ...state,
        featureToggles: action.payload,
      };
    },
  },
});

export default slice.reducer;

// Actions
const { agentUpdate, featureTogglesLoaded } = slice.actions;

export const updateAgent =
  (agent: { agent: string | null; agency: string | null }) =>
  async (dispatch: AppDispatch) => {
    dispatch(agentUpdate(agent));
  };

export const useLoadFeatureToggles = () => {
  const dispatch = useAppDispatch();
  const [, getAuthToken] = useAzureAuth();

  const loadFeatureToggles = useCallback(async () => {
    try {
      const authToken = await getAuthToken();

      if (!authToken) return;

      const { data: featureToggles } =
        await campaignsApi(authToken).get<FeatureToggles>('GetFeatureToggles');

      dispatch(featureTogglesLoaded(featureToggles));
    } catch (err: any) {
      console.error(err);
      dispatch(setAndShowErrorToast(err.message));
      return err.message;
    }
  }, [dispatch, getAuthToken]);

  return loadFeatureToggles;
};
