import React from 'react';
import moment from 'moment';
import {
  AgxBodyText,
  AgxColumn,
  Images,
  PropertyAddress,
  ContractDetailModel,
  CampaignCompletionState,
} from '@urbanx/agx-ui-components';
import { useAppSelector } from 'hooks/useAppSelector';
import placeHolderImage from 'assets/images/placeholder-property.png';
import ContractCard from './ContractCard';
import './ContractDetails.scss';

interface ContractDetailsProps {
  property: PropertyAddress;
  contractDetails: ContractDetailModel[];
  completionState: CampaignCompletionState;
  propertyImageUrls: { thumbnail: string | null };
}

const ContractDetails: React.FC<ContractDetailsProps> = ({
  property,
  contractDetails,
  completionState,
  propertyImageUrls: imageUrls,
}) => {
  const {
    featureToggles: { generateSalesContracts },
  } = useAppSelector(state => state.agentInfo);

  const sortByDate = (a: ContractDetailModel, b: ContractDetailModel) =>
    moment.utc(b.lastUpdate).diff(moment.utc(a.lastUpdate));

  const allContracts =
    contractDetails
      ?.map((contract: ContractDetailModel) => {
        return {
          ...contract,
          contractStage: contract.contractStage,
          formType: contract.inProgressForm?.formType,
        };
      })
      .sort(sortByDate) ?? [];

  if (allContracts.length === 0) {
    return (
      <AgxColumn
        fill
        centered
        centerJustified
        mediumGap
        extraClasses="contractRequestContracts"
      >
        <Images.SparkleForm />
        {generateSalesContracts ? (
          <>
            <AgxBodyText medium>
              You haven't created a contract yet!
            </AgxBodyText>
            <AgxBodyText medium>When you do, they'll appear here.</AgxBodyText>
          </>
        ) : (
          <>
            <AgxBodyText large>COMING SOON</AgxBodyText>
            <AgxBodyText medium>
              Sales Advice & Contract Generation will land here soon.
            </AgxBodyText>
          </>
        )}
      </AgxColumn>
    );
  }

  return (
    <AgxColumn extraClasses="contractRequestContracts" largeGap>
      {allContracts.sort(sortByDate).map((contract, idx) => (
        <ContractCard
          key={idx}
          state={property.state}
          contract={contract}
          isArchived={completionState.completionType != null}
          propertyImage={imageUrls?.thumbnail ?? placeHolderImage}
        />
      ))}
    </AgxColumn>
  );
};

export default ContractDetails;
