import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ParcelDetails } from '@urbanx/agx-ui-components';
import { LoadingState } from 'utils/loadingState';
import { rpDataApi } from 'Api/RpData/rpDataApi';
import { AppDispatch } from 'store/store';
import {
  AllPropertyDetails,
  PropertySummaryResponseModel,
  TitleReferenceResponse,
  VendorDetailsResponseModel,
} from 'Api/RpData/Types/types';

interface RpDataReducerState {
  propertyDetails: {
    loadingState: LoadingState;
    items: AllPropertyDetails[];
  };
  titleReference: {
    loadingState: LoadingState;
    campaignId: string | null;
    items: ParcelDetails[];
  };
}

const initialState = {
  propertyDetails: {
    loadingState: LoadingState.NotLoaded,
    items: [],
  },
  titleReference: {
    loadingState: LoadingState.NotLoaded,
    campaignId: null,
    items: [],
  },
} satisfies RpDataReducerState as RpDataReducerState;

const slice = createSlice({
  name: 'rpData',
  initialState,
  reducers: {
    clearPropertyDetails: state => {
      return {
        ...state,
        ...initialState,
      };
    },
    startLoadingPropertyDetails: state => {
      return {
        ...state,
        propertyDetails: {
          ...state.propertyDetails,
          loadingState: LoadingState.Loading,
        },
      };
    },
    propertyDetailsLoaded: (
      state,
      action: PayloadAction<AllPropertyDetails[]>
    ) => {
      return {
        ...state,
        propertyDetails: {
          ...state.propertyDetails,
          items: [...state.propertyDetails.items, ...action.payload],
          loadingState: LoadingState.Loaded,
        },
      };
    },
    errorLoadingPropertyDetails: state => {
      return {
        ...state,
        ...initialState,
        propertyDetails: {
          ...initialState.propertyDetails,
          loadingState: LoadingState.Failed,
        },
      };
    },
    startLoadingTitleReference: state => {
      return {
        ...state,
        titleReference: {
          ...state.titleReference,
          loadingState: LoadingState.Loading,
        },
      };
    },
    titleReferenceLoaded: (
      state,
      action: PayloadAction<{
        campaignId: string;
        parcels: ParcelDetails[];
      }>
    ) => {
      return {
        ...state,
        titleReference: {
          ...state.titleReference,
          campaignId: action.payload.campaignId,
          items: action.payload.parcels,
          loadingState: LoadingState.Loaded,
        },
      };
    },
    resetTitleReference: state => {
      return {
        ...state,
        titleReference: {
          ...initialState.titleReference,
        },
      };
    },
    errorLoadingTitleReference: state => {
      return {
        ...state,
        titleReference: {
          ...state.titleReference,
          loadingState: LoadingState.Failed,
        },
      };
    },
  },
});

export default slice.reducer;

const {
  clearPropertyDetails,
  startLoadingPropertyDetails,
  propertyDetailsLoaded,
  errorLoadingPropertyDetails,
  startLoadingTitleReference,
  titleReferenceLoaded,
  resetTitleReference,
  errorLoadingTitleReference,
} = slice.actions;

export { clearPropertyDetails, resetTitleReference };

const getPropertyDetailsRequest = async (
  authToken: string,
  propertyId: number
): Promise<AllPropertyDetails | null> => {
  try {
    const [propertySummaryResponse, vendorDetailsResponse] = await Promise.all([
      rpDataApi(authToken).get<PropertySummaryResponseModel>(
        'GetPropertySummary',
        {
          PropertyId: propertyId,
        }
      ),
      rpDataApi(authToken).get<VendorDetailsResponseModel>('GetVendorDetails', {
        PropertyId: propertyId,
      }),
    ]);

    const { status: propertySummaryStatus, data: propertySummary } =
      propertySummaryResponse;
    const { status: vendorDetailsStatus, data: vendorDetails } =
      vendorDetailsResponse;

    if (propertySummaryStatus === 200 && vendorDetailsStatus === 200) {
      const propertySearchData = {
        ...propertySummary,
        ...vendorDetails,
      };

      return propertySearchData;
    }
  } catch (err) {
    console.error(err);
  }

  return null;
};

export const getPropertyDetails =
  (authToken: string, propertyIds: number[]) =>
  async (dispatch: AppDispatch) => {
    try {
      if (propertyIds.length === 0) {
        dispatch(clearPropertyDetails());
        return;
      }

      dispatch(startLoadingPropertyDetails());

      const propertyDetails = await Promise.all(
        propertyIds.map(propertyId =>
          getPropertyDetailsRequest(authToken, propertyId)
        )
      );

      dispatch(
        propertyDetailsLoaded(
          propertyDetails.filter(
            property => property !== null
          ) as AllPropertyDetails[]
        )
      );
    } catch (err) {
      console.error(err);
      dispatch(errorLoadingPropertyDetails());
    }
  };

export const fetchTitleReferences =
  (
    authToken: string,
    campaignId: string,
    existingParcels: ParcelDetails[],
    propertyId?: string | null,
    state?: string | null
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoadingTitleReference());

      const {
        data: { parcels },
      } = await rpDataApi(authToken).post<TitleReferenceResponse>(
        'GetTitleReference',
        {
          propertyId,
          state,
          parcels: existingParcels,
        }
      );

      dispatch(titleReferenceLoaded({ campaignId, parcels }));
    } catch (err: any) {
      console.error(err);
      dispatch(errorLoadingTitleReference());
    }
  };
