import { useReducer } from 'react';

export const ADD_FILES = 'ADD_FILES';
export const REMOVE_FILE = 'REMOVE_FILE';
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const ADD_UPLOADED_FILE = 'ADD_UPLOADED_FILE';

const initialState = [];

export const uploadingFilesReducer = (state, action) => {
  switch (action.type) {
    case ADD_FILES:
      return [...state, ...action.payload];
    case REMOVE_FILE:
      return state.filter(file => file.id !== action.payload.id);
    case UPDATE_PROGRESS:
      return state.map(file => {
        if (file.id === action.payload.id) {
          return { ...file, progress: action.payload.progress };
        }

        return file;
      });
    case ADD_UPLOADED_FILE:
      return state.map(file => {
        if (file.id === action.payload.id) {
          return { ...file, uploadedFile: action.payload.uploadedFile };
        }

        return file;
      });
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const useUploadingFilesReducer = () => {
  const [uploadingFiles, dispatch] = useReducer(
    uploadingFilesReducer,
    initialState
  );

  const updateFileUploadProgress = (id, progress) => {
    dispatch({ type: UPDATE_PROGRESS, payload: { id, progress } });
  };

  const addUploadingFiles = uploadingFiles => {
    dispatch({ type: ADD_FILES, payload: uploadingFiles });
  };

  const removeUploadingFile = uploadingFile => {
    dispatch({ type: REMOVE_FILE, payload: uploadingFile });
  };

  const addFileToUploadingFile = (uploadingFile, uploadedFile) => {
    dispatch({
      type: ADD_UPLOADED_FILE,
      payload: { id: uploadingFile.id, uploadedFile },
    });
  };

  return [
    uploadingFiles,
    updateFileUploadProgress,
    addUploadingFiles,
    removeUploadingFile,
    addFileToUploadingFile,
  ];
};
