import {
  AgxBodyText,
  AgxButton,
  AgxColumn,
  AgxHeader,
  AgxRow,
  AgxSlideUpModal,
  AgxTextInput,
  IBaseSpecialCondition,
  Images,
} from '@urbanx/agx-ui-components';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { Breakpoints, ScreenSize } from 'utils/screen';
import Collapsible from 'react-collapsible';
import { CircularSelector } from '../SearchNow/CircularSelector';
import './SpecialClause.scss';

interface ISpecialClause {
  id: string;
  title: string;
  referenceNumber: string;
  order: string | null;
  hasSubPoints: boolean;
  isCustomClause: boolean;
  subPoints: ISpecialClause[];
  template?: string;
  expandable?: boolean;
}

interface Props {
  id: string;
  key: string;
  defaultValue: ISpecialClause;
  onSelectedClause: (value: ISpecialClause | null) => void;
  validate: boolean;
}

const SpecialClause = (props: Props) => {
  const { defaultValue, onSelectedClause, validate } = props;
  const {
    CustomSpecialConditions: customConditions,
    PredefinedSpecialConditions: predefinedSpecialConditions,
  } = useAppSelector(state => state.form.formValues);

  const [openConditionsModal, setOpenConditionsModal] = useState(false);
  const [clauseAdded, setClauseAdded] = useState(false);
  const [selectedClause, setSelectedClause] = useState<ISpecialClause>();
  const isMobile = ScreenSize() === Breakpoints.Mobile;
  const [combinedClause, setCombinedClause] = useState<ISpecialClause[]>([]);
  const [conditionsExpanded, setConditionsExpanded] = useState<Set<string>>(
    new Set()
  );

  const onConditionSelect = (clause: ISpecialClause) => {
    if (selectedClause?.title === clause.title) {
      setSelectedClause(undefined);
    } else {
      setSelectedClause(clause);
    }
  };

  const onConditionExpanded = (conditionId: string) => {
    const newSet = new Set(conditionsExpanded);
    newSet.add(conditionId);
    setConditionsExpanded(newSet);
  };

  const onConditionClosed = (conditionId: string) => {
    const newSet = new Set(conditionsExpanded);
    newSet.delete(conditionId);
    setConditionsExpanded(newSet);
  };

  const onCancel = () => {
    setOpenConditionsModal(false);
    setConditionsExpanded(new Set());
    if (
      (defaultValue && !selectedClause) ||
      defaultValue?.title !== selectedClause?.title
    ) {
      setSelectedClause(defaultValue);
    }
  };

  const onAdd = () => {
    onSelectedClause(selectedClause ?? null);
    setClauseAdded(true);
    setConditionsExpanded(new Set());
    setOpenConditionsModal(false);
  };

  const actionButtons = (
    <AgxColumn fill>
      <AgxRow justifyCenter largeGap>
        <AgxButton hollow text="Cancel" large onClick={() => onCancel()} />
        <AgxButton
          primary
          text="Add"
          onClick={() => onAdd()}
          rightIcon={<Images.Checkmark />}
          large
        />
      </AgxRow>
    </AgxColumn>
  );

  useEffect(() => {
    const predefinedClauses: ISpecialClause[] = [];
    const customClauses: ISpecialClause[] = [];
    if (predefinedSpecialConditions) {
      const selectedPredefinedConditions = Object.entries(
        predefinedSpecialConditions
      ).filter(
        ([, value]) => (value as unknown as IBaseSpecialCondition).isSelected
      );
      selectedPredefinedConditions.sort(([, a], [, b]) => {
        if (
          (a as IBaseSpecialCondition).order <
          (b as IBaseSpecialCondition).order
        ) {
          return -1;
        } else if (
          (a as IBaseSpecialCondition).order >
          (b as IBaseSpecialCondition).order
        ) {
          return 1;
        } else {
          return 0;
        }
      });

      selectedPredefinedConditions.forEach(([, condition], index) => {
        const predefinedCondition: ISpecialClause = {
          id: (condition as IBaseSpecialCondition).id,
          title: (condition as IBaseSpecialCondition).title || '',
          expandable: (condition as IBaseSpecialCondition).expandable,
          order: null,
          referenceNumber: `${index + 1}`,
          template:
            !(condition as IBaseSpecialCondition).expandable &&
            ((condition as IBaseSpecialCondition)?.expandedSubpoints?.length ??
              0) > 0
              ? (condition as IBaseSpecialCondition)?.expandedSubpoints?.join(
                  ''
                )
              : (condition as IBaseSpecialCondition).template,
          hasSubPoints:
            ((condition as IBaseSpecialCondition)?.expandedSubpoints || [])
              .length > 0,
          isCustomClause: true,
          subPoints: (
            (condition as IBaseSpecialCondition)?.expandedSubpoints || []
          ).map((subpoint, subIndex) => ({
            id: `predefined-${index + 1}.${subIndex + 1}`,
            title: subpoint.replaceAll('#index#', `${index + 1}.`),
            order: null,
            referenceNumber: `${index + 1}.${subIndex + 1}`,
            hasSubPoints: false,
            isCustomClause: false,
            subPoints: [],
          })),
        };

        predefinedClauses.push(predefinedCondition);
      });
    }

    if (customConditions) {
      (customConditions as IBaseSpecialCondition[]).forEach(
        (condition, index) => {
          const customClause: ISpecialClause = {
            id: condition.id,
            title: condition.title || '',
            order: null,
            referenceNumber: `${predefinedClauses.length + (index + 1)}`,
            expandable: condition.subPoints?.length > 0,
            hasSubPoints: condition.subPoints?.length > 0,
            isCustomClause: true,
            subPoints: condition.subPoints?.map(subpoint => ({
              id: subpoint.id,
              title: subpoint.title || '',
              order: null,
              referenceNumber: `${condition.order}.${subpoint.order}`,
              hasSubPoints: false,
              isCustomClause: true,
              subPoints: [],
            })),
          };
          customClauses.push(customClause);
        }
      );
    }
    setCombinedClause([...predefinedClauses, ...customClauses]);

    if (defaultValue) {
      setSelectedClause(defaultValue);
      setClauseAdded(true);
    }
  }, [defaultValue, customConditions, predefinedSpecialConditions]);

  const conditionsList = (
    <AgxColumn fill extraClasses={'contentPadding'}>
      <AgxColumn
        largeGap
        extraClasses={'specialClauseContainer'}
        key={`specialClauseContainer`}
      >
        <AgxHeader size={isMobile ? 2 : 3}>Choose clause</AgxHeader>
      </AgxColumn>
      <AgxColumn extraClasses={'specialClauseBody'}>
        {combinedClause.map(clause => {
          return (
            <Collapsible
              trigger={
                <AgxColumn fill extraClasses={'collapsibleHeader'}>
                  <AgxRow spaceBetween centered>
                    <AgxRow extraClasses={'collapsibleTitle'}>
                      <AgxRow>
                        {conditionsExpanded.has(clause.title || '') ? (
                          <Images.ChevronUp />
                        ) : (
                          <Images.ChevronDown />
                        )}
                      </AgxRow>
                      <AgxRow extraClasses={'headerContainer'} largeGap>
                        <span className="headerText">
                          {`${clause.referenceNumber}. ${clause.title}`}
                        </span>
                      </AgxRow>
                    </AgxRow>
                    {!clause.expandable && (
                      <AgxRow
                        extraClasses={'checkboxContainer'}
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        <CircularSelector
                          selected={selectedClause?.title === clause.title}
                          onClicked={() => onConditionSelect(clause)}
                        />
                      </AgxRow>
                    )}
                  </AgxRow>
                </AgxColumn>
              }
              onOpen={() => onConditionExpanded(clause.title || '')}
              onClose={() => onConditionClosed(clause.title || '')}
              id={`id-${clause.id}`}
              key={`key-${clause.id}`}
            >
              <AgxColumn fill>
                <AgxColumn veryLargeGap extraClasses={'paddingLeft32'}>
                  {clause.subPoints?.length > 0 && clause.expandable ? (
                    clause.subPoints?.map(subPoint => {
                      return (
                        <AgxRow spaceBetween key={`subpoint-${subPoint.id}`}>
                          <AgxRow fill largeGap>
                            <AgxBodyText
                              medium
                              extraClasses="subpointStyle"
                            >{`${subPoint.referenceNumber}`}</AgxBodyText>
                            <AgxBodyText medium extraClasses="subpointStyle">
                              {subPoint.title}
                            </AgxBodyText>
                          </AgxRow>
                          <CircularSelector
                            selected={selectedClause?.title === subPoint.title}
                            onClicked={() => onConditionSelect(subPoint)}
                          />
                        </AgxRow>
                      );
                    })
                  ) : (
                    <AgxRow spaceBetween>
                      <AgxRow fill largeGap>
                        <AgxBodyText medium extraClasses="subpointStyle">
                          {clause.template}
                        </AgxBodyText>
                      </AgxRow>
                    </AgxRow>
                  )}
                </AgxColumn>
              </AgxColumn>
            </Collapsible>
          );
        })}
      </AgxColumn>
    </AgxColumn>
  );

  return (
    <AgxColumn fill>
      <AgxRow fill onClick={() => setOpenConditionsModal(true)}>
        <AgxTextInput
          id="specialClause"
          onInputValueChange={() => {}}
          stretch
          readonly
          defaultValue={
            clauseAdded
              ? `${selectedClause?.referenceNumber || ''}    ${selectedClause?.title || ''}`
              : ''
          }
          noOptionalLabel
          parentControlValue
          extraClasses="inputCursorDefault"
          rightIcon={<Images.Dropdown />}
          error={
            validate && !selectedClause?.title
              ? 'Select special condition'
              : undefined
          }
        />
      </AgxRow>
      {openConditionsModal && (
        <AgxSlideUpModal
          closeButton
          content={conditionsList}
          desktop={!isMobile}
          onClose={() => onCancel()}
          actionButtons={actionButtons}
          noPadding
          noScroll
        />
      )}
    </AgxColumn>
  );
};

export default SpecialClause;
